/** @jsx jsx */
import jsx from "../jsx";
import React from "react";
import theme from "../theme";
import Avatar from "../components/Avatar";
import avatarImg from "../assets/images/avatar.png";
import ColorModeApplier from "./ColorModeApplier";
import { useColorMode } from "theme-ui";
import { Div, A} from "../elements";

const modes = ["light", "black", "dark", "deep", "hack", "pink"];

function Header() {
  const [colorMode, setColorMode] = useColorMode();
  return (
    <header
      px={3}
      py={2}
      borderBottom="1px solid"
      borderColor="black-20"
      display="flex"
      alignItems="center"
      style={{ "box-shadow": "0 1px 9px 0 currentcolor" }}
    >
      <Div maxWidth="6rem" mr={2}>
        <A display="block" href="/">
          <Avatar src={avatarImg} borderRadius={100} width={[32, 64]} />
        </A>
      </Div>
      <A
        ml="auto"
        mr={3}
        fontSize={0}
        fontWeight={700}
        color="currentcolor"
        href="/"
        title="Readings"
        style={{
          fontFamily: theme.typefaces.mono
        }}
      >
        Readings
      </A>
      <A
        fontSize={0}
        mr={3}
        fontWeight={700}
        color="currentcolor"
        href="/"
        title="Writings"
        style={{ fontFamily: theme.typefaces.mono }}
      >
        Writings
      </A>
      <A
        fontSize={0}
        mr={3}
        fontWeight={700}
        color="currentcolor"
        href="/"
        title="Frames"
        style={{ fontFamily: theme.typefaces.mono }}
      >
        Frames
      </A>
      <A
        fontSize={0}
        mr={3}
        fontWeight={700}
        color="currentcolor"
        href="/"
        title="Frames"
        style={{ fontFamily: theme.typefaces.mono }}
      >
        Music
      </A>
      <ColorModeApplier
        mode={colorMode}
        onClick={e => {
          const i = modes.indexOf(colorMode);
          const n = (i + 1) % modes.length;
          setColorMode(modes[n]);
        }}
      />
    </header>
  );
}

export default Header;
